
$(window).on('load', function () {
      $('.masonry').masonry({
        // options
        itemSelector: '.item'
      });
 });
 
$(document).ready(function(){

	$(".cover").each(function( index ) {
		if(	$(this).attr("data-image")	){
			var image = $(this).data("image");
			$(this).css("background-image","url("+image+")");
		
		}
	});
	

	$("#hmbrgr").on("click",function(){
		
		
		$('.ml12').each(function(){
		  $(this).html($(this).text().replace(/([^\x00-\x80]|\w)/g, "<span class='letter'>$&</span>"));
		});
		anime.timeline({loop: true})
		  .add({
		    targets: '.ml12 .letter',
		    translateX: [40,0],
		    translateZ: 0,
		    opacity: [0,1],
		    easing: "easeOutExpo",
		    duration: 1200,
		    delay: function(el, i) {
		      return 500 + 30 * i;
		    }
		  }).add({
		    targets: '.ml12 .letter',
		    translateX: [0,-30],
		    opacity: [1,0],
		    easing: "easeInExpo",
		    duration: 1100,
		    delay: function(el, i) {
		      return 100 + 30 * i;
		    }
		  });
	});
		
	/* html5 images en background sur les classes .cover */
	
	
	


	//Materialize.updateTextFields();
	//$('.tooltipped').tooltip({delay: 50});
	//$('select').material_select();
	//$(".dropdown-button").dropdown();
	
	//$('.modal').modal();
	//$('.materialboxed').materialbox();
	
	/*
	forms
	*/
	if($(".ajaxForms" ).length>0){
		
		
		
		
		
		/* send to ajax */	
		   $(".ajaxForms").submit(function(e){
		       e.preventDefault();
			   Materialize.updateTextFields();
			  
		   	
		       var form = $(this);
			   var cible= $(this).attr("action");
	
		            $.post(form.attr('action'), form.serialize(), function(data){
		               //var data = $.parseJSON(data);
	
			                if(data.status === true){
			                   console.log("ok");
	
			                }else{
			                	  console.log(data.status);
			                }
	
	
		            });
		        return false;
		   });
		   
		   
	 }
	 
	 $('.js-scrollTo').on('click', function() { // Au clic sur un élément
	 			var page = $(this).attr('href'); // Page cible
	 			var speed = 750; // Durée de l'animation (en ms)
	 			$('html, body').animate( { scrollTop: $(page).offset().top }, speed ); // Go
	 			return false;
	 });

	
	
	



});





